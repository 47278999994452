@import './variables.css';


.section {
    margin-block: var(--pad-xl);
    border-radius: var(--pad);
}


/* ======================== ABOUT SECTION ======================== */

.section-about {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
    gap: var(--pad-xl);
    /* background-image: linear-gradient(to bottom right, #2575fc 0%, #6a11cb 100%); */
    /* border-radius: var(--pad-sm); */
    /* border-top-right-radius: var(--pad-lg);
    border-bottom-left-radius: var(--pad-lg); */
    padding-block: var(--pad-xl);
    /* padding-inline: var(--pad-xxs); */
    /* inset | offset-x | offset-y | color */
    /* box-shadow: 3px 5px .2em var(--color-grey-1), inset 1px 2px .4em var(--color-grey-5); */
}


/* ======================== ABOUT left ======================== */
.section-about .left-about {
    display: grid;
    align-content: center;
    justify-content: start;
    gap: var(--pad-md);
    position: relative;
}

article.about-info-item {
    display: grid;
    grid-template-columns: var(--pad-xl) 1fr;
    gap: var(--pad);
}

img[class="about-info-item-img"] {
    background-color: var(--color-white);
    padding: var(--pad-sm);
    object-fit: contain;
    aspect-ratio: 1 / 1;
    border-radius: var(--text-sm);
    width: 100%;
}

article.about-info-item h5 {
    font-size: var(--text);
}

.left-about a {
    justify-self: center;
}

#box-image-left {
    display: none;
}

@media (max-width: 58.75rem) {
    #box-image-left {
        display: block;
        position: absolute;
        top: -55px;
        aspect-ratio: 1 / 1;
        animation: scaleUp 8s infinite;
    }
}


/* ======================== ABOUT right ======================== */
.section-about .right-about {
    transition: var(--transition);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--pad), auto));
    grid-template-rows: repeat(auto-fit, minmax(var(--pad), auto));
    justify-self: center;
}

.right-about img[class="box-image"] {
    grid-column: 1 / 4;
    grid-row: 1 / 4;

    aspect-ratio: 1 / 1;
    animation: scaleUp 8s infinite;
    z-index: 1;
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.right-about img[class="about-image"] {
    grid-column: 2 / 8;
    grid-row: 2 / 6;
    border-radius: var(--pad-xs);
    border-top-left-radius: var(--pad-lg);
    border-bottom-right-radius: var(--pad-lg);

    background-size: cover;
    aspect-ratio: 2 / 3;

    transition: var(--transition);
    z-index: 2;
}

.section-about:hover img[class="about-image"] {
    transform: scale(1.05);
}

@media (max-width: 58.75rem) {
    .right-about img {
        display: none;
    }
}

/* ======================== SERVICES SECTION ======================== */

.section-services {
    display: grid;
    gap: var(--pad-lg);
    margin-block: var(--pad-xl);
    /* background-image: linear-gradient(19deg, #21D4FD 0%, #6a11cb 100%); */
    /* inset | offset-x | offset-y | color */
    box-shadow: inset 0 0 .3rem .1em var(--color-accent), 0 0 .8em .5em var(--color-primary);
    padding-block: var(--pad-md);
    border-radius: var(--pad);
}

.services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
    gap: var(--pad);
}

.service-item {
    background-color: var(--color-grey-9);
    border-radius: .44rem;
    transition: var(--transition);
    position: relative;
    background: linear-gradient(to top, var(--color-grey-9) 55%, var(--color-grey-7));
}

.service-item:hover {
    box-shadow: .62rem .62rem 0px var(--color-border);
    transform: translateY(-.31rem);
}

.service-item::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: .4rem;
    background: var(--color-gradient-primary-2);
    width: 100%;
    transition: var(--transition);
    transform: scale(0);
    border-bottom-left-radius: .31rem;
    border-bottom-right-radius: .31rem;
}

.service-item:hover::before {
    transform: scale(1);
}

.s-item-container {
    display: grid;
    padding: var(--pad-xxs);
    padding-block-end: var(--pad-sm);
    align-content: space-evenly;
    justify-items: center;
    text-align: center;
    gap: var(--pad-sm);
    /* border-top: .08rem solid var(--color-border);
    border-left: .08rem solid var(--color-border);
    border-right: .08rem solid var(--color-border); */
    /* border-bottom: .1rem solid var(--color-grey-7); */
    border-radius: .44rem;
}

@media (max-width: 34em) {
    .s-item-container {
        outline: .1rem solid var(--color-grey-7);
        padding-block-end: var(--pad);
    }
}


.s-item-container img,
.s-item-container video,
.s-item-container iframe,
.s-item-container video-js {
    aspect-ratio: 16/9;
    object-fit: contain;
    border-top-right-radius: .44rem;
    border-top-left-radius: .44rem;
    width: 100%;
    height: 100%;
    border: 0;
}

.service-item h4 {
    font-size: var(--text-lg);
    position: relative;
    padding-block-end: var(--pad-sm);
    /* transition: color .2s ease-in-out; */
}

/* .service-item:hover h4 {
    color: var(--color-lavender);
} */

.service-item h4::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 70%;
    height: .3rem;
    background: var(--color-lavender);
    transition: background .4s ease-in-out;
}

.service-item:hover h4::after {
    background: var(--color-gradient-primary-2);
}


/* ======================== Personalized Section ======================== */

.section-personalized {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
    gap: var(--pad);
    /* background-color: var(--color-bg-3); */
    margin-block-end: var(--pad-lg);
    box-shadow: 0 .31rem 1.12rem #212529b7;
    background: rgb(63, 94, 251);
    background: linear-gradient(291deg, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%);
}

/* ======================== Personalized Sec - LEFT ======================== */

.section-personalized .left-rec {
    overflow: hidden;
    border-top-left-radius: .31rem;
}

.section-personalized .left-rec img {
    border-top-left-radius: .31rem;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    filter: grayscale(100%);
    height: 100%;
    transition: var(--transition);
}

.section-personalized:hover .left-rec img {
    filter: grayscale(0);
    transform: scale(1.05);
}

/* ======================== Personalized Sec - RIGHT ======================== */

.section-personalized .right-sec {
    display: grid;
    align-content: center;
}

.section-personalized .right-sec .rec-content {
    display: grid;
    justify-items: start;
    gap: var(--pad-md);
    padding: var(--pad);
}

.section-personalized .right-sec .rec-content h3 {
    position: relative;
    padding-block-end: var(--pad-md);
    font-size: var(--text-lg);
}

.section-personalized .right-sec .rec-content h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: min(20%, 20vw);
    height: .1rem;
    background-color: var(--color-accent);
    border-radius: .37rem;
}

.section-personalized .right-sec .rec-content p {
    color: var(--color-grey-4);
}

/* ======================== faq ======================== */

.section-faq {
    display: grid;
    gap: var(--pad);
    /* background-image: linear-gradient(170deg, #4158D0 45%, #C850C0 74%, #FFCC70 100%); */
    border-radius: var(--pad);


}

.faq-container {
    background: linear-gradient(to right, var(--color-grey-9) 45%, var(--color-grey-7));
    border-radius: .45rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 20rem), 1fr));
    gap: var(--pad);
    padding: var(--pad);
    text-align: center;
    place-items: center;
}

@media (max-width: 47em) {
    .faq-container {
        background: linear-gradient(to bottom, var(--color-grey-9) 40%, var(--color-grey-7));
    }
}

.faq-container .faq-img {
    overflow: hidden;
    border-radius: .45rem;
}

.faq-container img {
    border-radius: .45rem;
    object-fit: cover;
    aspect-ratio: 1;
    filter: grayscale(75%);
    transition: var(--transition);
    width: 100%;
    height: 100%;
}

.section-faq:hover img {
    filter: grayscale(0);
    transform: scale(1.2) rotate(-5deg);
}

.faq-container .faq-text {
    display: grid;
    place-items: center;
    gap: var(--pad);
}

.faq-container .faq-text h4 {
    font-size: calc(var(--text-md) * 1.12);
    font-weight: 600;
}

.faq-container .faq-text h6 {
    font-size: calc(var(--text) * 1.1);
    font-weight: 400;
    color: var(--color-font);
}

.faq-container .faq-text a {
    background: transparent;
    border: .12rem solid var(--color-grey-4);
    text-transform: initial;
    margin-block-start: var(--pad-xs);
}

.section-faq .faq-controls {
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq-controls .control {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin: 0 .2rem;
    cursor: pointer;
}

.control-1 {
    background-color: var(--color-accent);
    transform: scale(1.2);
}

.control-2 {
    background-color: var(--color-grey-9);
    /* transform: scale(0.85); */
}

.control-3 {
    background-color: var(--color-grey-9);
    /* transform: scale(0.6); */
}


/* ======================== SECTION financial ======================== */
section.section-financial {
    display: grid;
    /* background: linear-gradient(to top, #3F5EFB 60%, #21D4FD); */
    background-image: linear-gradient(19deg, #21D4FD 0%, #6a11cb 100%);
}

/* .section-financial article h3 {
    color: var(--color-gradient-primary-32);
} */

.section-financial article p {
    color: var(--color-grey-4);
    letter-spacing: .01ch;
}


.section-financial .financial-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
    gap: var(--pad);
}

.financial-item {
    display: grid;
    gap: var(--pad-lg);
    border-radius: .44rem;
    font-size: var(--font);
    border: .12rem solid var(--color-grey-9);
    transition: var(--transition);
    cursor: pointer;
    background: linear-gradient(to top, var(--color-grey-9) 40%, var(--color-grey-7));
}

.financial-item:hover {
    box-shadow: 10px 10px 0px #f8f9fa14;
    transform: translateY(-5px);
}

.financial-item [class="lottie"] {
    place-self: center;
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
}

.financial-item iframe {
    place-self: center;
    width: 100%;
    height: auto;
    border-radius: var(--pad-xs);
    border: 0;
    aspect-ratio: 1;
}

.financial-item .image {
    position: relative;
}

/* .financial-item .image img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    border-top-right-radius: .44rem;
    border-top-left-radius: .44rem;
} */

.financial-item .image .image-content {
    position: absolute;
    bottom: -.81rem;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);

    background: var(--color-gradient-primary-3);
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: .5rem 1rem;
    border-radius: var(--pad);
}

.financial-text {
    display: grid;
    place-items: center;
    gap: var(--pad);
    text-align: center;
    padding-inline: var(--pad);
}

.financial-text h5 {
    font-size: var(--text-md);
    color: var(--color-grey-1);
    transition: var(--transition);
}

.financial-item:hover .financial-text h5 {
    color: var(--color-accent);
}

.financial-text p {
    font-size: var(--text);
}

.financial-icons {
    border-top: 1px solid var(--color-grey-9);
}

.financial-icons ul {
    display: flex;
    align-items: center;
    gap: .85rem;
    font-size: var(--text);
    cursor: pointer;
    padding: var(--pad)
}



/* ======================== SECTION video ======================== */

.section-video {
    position: relative;
    height: 100%;
    width: 100vw;
    aspect-ratio: 16/9;
    box-shadow: 0 5px 18px #212529b7;
    margin-block: var(--pad-xl);
    border-radius: 0;
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0 0 .4rem .1rem var(--color-accent), 0 0 2rem 1rem var(--color-primary);
}


.section-video video,
.section-video video-js,
.section-video iframe {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border: 0;
    object-position: center;
}

.section-video .video-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: grid;
    place-items: center;


    border-radius: .44rem;
    z-index: 5;

    text-align: center;
    width: 95%;

    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 20rem), 1fr));
    gap: var(--pad); */
    /* place-items: center; */
    /* margin-inline: auto;
    width: 100%; */
    /* padding: 5rem 18rem; */
}

.left-vid-container h4 {
    padding-top: .6rem;
    position: relative;
}

.left-vid-container h4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: clamp(.2rem, .2rem + 0.15vh, .3rem);
    background: var(--color-gradient-primary-3);
    border-radius: .44rem;
}

.input-control {
    /* display: flex;
    align-items: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: grid;
    grid-template-columns: auto 1fr; */
    /* place-items: center; */
    /* position: relative; */
    /* place-items: center; */
}

.input-control input {
    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 30px;
    border: .12rem solid var(--color-grey-9);
    font-size: 1.2rem;
    outline: none;
    transition: var(--transition);
    box-shadow: 0 .44rem 18px #212529c9;
    z-index: 5;
    text-transform: initial;
}

.input-control input:focus {
    border: clamp(.15rem, .15rem + 0.15vh, .22rem) solid var(--color-accent);
}

.input-control a {
    /* position: absolute; */
    /* right: 1px; */
    text-transform: initial;
    height: 100%;
    width: 55%;
    padding: var(--pad);
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0 0 0 / 20%), rgba(0 0 0 / 20%));
    /* opacity: 0.5; */
    /* background-color: var(--color-bg); */
}

/* ======================== SECTION gallery ======================== */
.section-gallery {
    display: grid;
    gap: var(--pad);
}


.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--pad);
}




.grid-img {
    overflow: hidden;
    transition: var(--transition);
}

.grid-img:hover {
    transform: scale(0.94);
    border-radius: .44rem;
}



.grid-img img {
    border-top-left-radius: .44rem;
    border-bottom-left-radius: .44rem;
    border-top-right-radius: .12rem;
    border-bottom-right-radius: .12rem;
    transition: var(--transition);
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.grid-img img:hover {
    transform: scale(1.2);
}

.grid-img:nth-child(1) img,
.grid-img:nth-child(4) img {
    aspect-ratio: 1;
}

.grid-img:nth-child(2) img,
.grid-img:nth-child(3) img {
    aspect-ratio: 2;
}


.grid-img:nth-child(2) {
    grid-column: span 2;
}

.grid-img:nth-child(3) {
    grid-column: span 2;
}


.gallery-paragraph {
    margin-inline: auto;
    text-align: center;
}


/* ======================== FOOTER ======================== */
.footer {
    margin-block-start: var(--pad-xl);
    background-color: var(--color-bg-3);
    color: var(--color-font);
    display: grid;
    gap: var(--pad-lg);
}

.footer h6 {
    font-size: 1.2rem;
}

.footer h4 {
    font-size: 1.5rem;
    color: var(--color-grey-4);
}

.footer a {
    color: var(--color-font);
    transition: var(--transition);
}

.footer a:hover {
    color: var(--color-accent);
    text-decoration: underline;
}

.footer i {
    font-size: 1.3rem;
    transition: var(--transition);
}

.footer i:hover {
    color: var(--color-accent);
}

.footer h1 {
    color: var(--color-white);
}









.bottom-nav {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 10rem), 1fr));
    gap: var(--pad);
    padding-block-end: var(--pad-xl);
    border-bottom: 1px solid var(--color-grey-9);
}

.bottom-nav .nav-title {
    padding-block-end: 1.5rem;
}

/* logo col */
.bottom-nav .logo-container {
    display: flex;
    flex-direction: column;
    gap: var(--pad);
}

.b-nav-icons {
    display: flex;
    gap: var(--pad);
    cursor: pointer;
}

/* middle two cols */
.nav-bottom .nav-item {
    margin-bottom: 1rem;
}


/* contact col */
.nav-bottom-contact .nav-item {
    display: grid;
    grid-template-columns: 30px auto;
}


/* last col */
.ig-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, .75fr);
    gap: .5rem;
}

.ig-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    aspect-ratio: 1;
}


.footer-copyright {
    justify-self: center;
    text-align: center;
    margin: var(--pad-sm);
}

.footer-copyright p span {
    color: var(--color-grey-4);
    cursor: pointer;
    transition: var(--transition);
}

.footer-copyright p span:hover {
    color: var(--color-accent);
}

/* ======================== INDEPENDENT COMPONENTS ======================== */

.title {
    font-size: var(--text-lg);
    font-weight: 700;
    padding-block-end: var(--pad);
}

.title-container {
    text-align: center;
    width: min(90%, 60ch);
    margin-inline: auto;
    padding: var(--pad);
}

.arrow svg {
    width: 18;
    height: 18;
    animation: moveLR 2s infinite;
}

@keyframes moveLR {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}