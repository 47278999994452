@import './assets/styles/variables.css';
@import './assets/styles/lines.css';
@import './assets/styles/header.css';
@import './assets/styles/buttons.css';
@import './assets/styles/utilities.css';
@import './assets/styles/main.css';
@import './assets/styles/footer.css';


/* RESETS */
* {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: system-ui;
    font-size: var(--text);
    color: var(--color-white);
    background-color: var(--color-bg);
    position: relative;
    /* min-height: 100%; */
    max-width: 100%;

    overflow-x: hidden;
}

img,
video,
iframe {
    max-inline-size: 100%;
    block-size: auto;
}

main img,
footer img {
    /* Only render when in viewport */
    /* content-visibility: auto; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: initial;
}

p {
    color: var(--color-font);
    font-weight: 300;
    line-height: 1.6;
}

a {
    font-weight: 500;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: var(--color-white);
}

.page-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #000;
}

/* background: linear-gradient(rgba(0 0 0 / 20%), rgba(0 0 0 / 20%)); */