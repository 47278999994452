:root {

        /* ========================= PADDING ==== */
        --pad-xl: calc(var(--pad) * 4);
        --pad-lg: calc(var(--pad) * 2);
        --pad-md: calc(var(--pad) * 1.5);
        --pad: clamp(1rem, 1vw + 0.5rem, 3rem);
        --pad-sm: calc(var(--pad) / 2);
        --pad-xs: calc(var(--pad) / 4);
        --pad-xxs: calc(var(--pad) / 6);

        /* ========================= FONT SIZES ==== */
        --text-xl: calc(var(--text) * 4);
        --text-lg: calc(var(--text) * 2);
        --text-md: calc(var(--text) * 1.5);
        --text: clamp(1rem, 1rem + 0.20vw, 1.6rem);
        --text-sm: calc(var(--text) / 2);
        --text-xs: calc(var(--text) / 4);

        --navbar-height: clamp(4.5rem, 6rem, 7.5rem);

        --title-sm: 1.25rem;
        --title: 1.5rem;
        --title-lg: 2rem;

        /* ========================= COLORS ==== */
        --color-primary: #554dde;
        --color-accent: #f44e77;
        --color-secondary-dark: #16194f;
        --color-bg: #101010;
        --color-bg-2: #10121a;
        --color-bg-3: #111111;
        --color-lavender: #9a94ff;
        --color-grey-light: #191919;
        --color-grey-light-2: #1d1d1d;
        --color-white: #ffffff;
        --color-black: #000000;
        --color-neutral: #f2f6ff;
        --color-gradient-primary: linear-gradient(143.82deg,
                        #554dde 4.75%,
                        rgba(85, 77, 222, 0) 61.12%);
        --color-gradient-accent: linear-gradient(132.34deg,
                        #f44e77 -7.22%,
                        rgba(244, 78, 119, 0) 77.9%);
        --color-gradient-primary-2: linear-gradient(171.21deg,
                        #554dde -10.74%,
                        #f44e77 112.82%,
                        #f44e77 112.82%);
        --color-gradient-primary-3: linear-gradient(300.81deg,
                        #554dde -17.99%,
                        #f44e77 111.32%,
                        #f44e77 111.32%);
        --color-gradient-primary-4: linear-gradient(158deg, rgba(154, 148, 255, 1) 0%, rgba(85, 77, 222, 1) 35%, rgba(244, 78, 119, 0) 100%);
        --color-gray: #6c757d;
        --color-grey: #343a40;
        --color-font: #a2a2a2;
        --color-grey-1: #f8f9fa;
        --color-grey-2: #e9ecef;
        --color-grey-3: #dee2e6;
        --color-grey-4: #ced4da;
        --color-grey-5: #adb5bd;
        --color-grey-6: #6c757d;
        --color-grey-7: #495057;
        --color-grey-8: #343a40;
        --color-grey-9: #212529b7;
        --color-border: #f8f9fa14;

        /* ========================= ANIMATION ==== */
        --transition: all 0.4s ease-in-out;

}