@import './variables.css';

.btn {
    overflow: hidden;
}

.main-btn {
    position: relative;
    text-transform: uppercase;
    font-size: var(--text);
    font-weight: 300;
    background: var(--color-gradient-primary-3);
    padding: var(--pad);
    border-radius: var(--pad-xl);
    transition: var(--transition);
    white-space: nowrap;
}

.main-btn-circle {
    position: absolute;
    top: 5px;
    right: -50%;
    width: 11.25rem;
    height: 11.25rem;
    background: rgba(255 255 255 / .2);
    border-radius: 50%;
    transition: all .5s cubic-bezier(.13, .86, .13, 1);
}

.main-btn:hover .main-btn-circle {
    top: -15px;
    right: -40%;
}

/* ======================== something more ======================== */

a.more {
    font-size: calc(var(--text) * 1.1);
    padding: var(--pad);
    font-weight: 300;
    position: relative;
    border: .12rem solid var(--color-grey-7);
    border-radius: var(--pad-xl);
    color: var(--color-accent);
    transition: var(--transition);
}

a.more:hover {
    color: var(--color-white);
    background: var(--color-accent);
    transform: translateY(-5px);
}

a.more::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-gradient-primary-2);
    border-radius: var(--pad-xl);
    transform: scale(0);
    transition: var(--transition);
    z-index: -1;
}

a.more:hover::before {
    transform: scale(1);

}


/* ======================== hamburger ======================== */

.mobile-nav-toggle {
    --button-color: var(--color-lavender);
    stroke: var(--button-color);
    fill: none;
    background: transparent;
    border: 0;
}

.mobile-nav-toggle {
    display: none;
}

@media (max-width: 58.75rem) {
    .mobile-nav-toggle {
        display: block;
        z-index: 9999;

        position: absolute;
        top: 0;
        right: var(--text-xs);
    }

    .mobile-nav-toggle .hamburger {
        transition: translate 1s, rotate 1s;
    }

    .mobile-nav-toggle[data-pressed="true"] .hamburger {
        translate: 10px 0px;
        rotate: .125turn;
    }

    .mobile-nav-toggle .line {
        transition: .8s;
        stroke-dasharray: 60 31 40 300;
    }

    .mobile-nav-toggle .line {
        stroke-width: 10;
    }

    .mobile-nav-toggle[data-pressed="true"] .line {
        stroke-dasharray: 60 105 60 300;
        stroke-dashoffset: -90;
    }
}