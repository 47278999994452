.wrapper {
    /* display: grid; */
    width: min(80rem, 100% - 1.25rem);
    /* width: calc(100vw - 20px);
    max-width: 70ch; */
    margin-inline: auto;
    padding: var(--pad);
    /* border: clamp(10px, 5vw, 30px) solid; */
}

/* ======================== CARDS  ======================== */

/* .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 20rem), 1fr));
    gap: var(--pad-md);
} */

/* ======================== IMAGES  ======================== */




/* <img
 src="small-image.png"
 alt="A description of the image."
 width="300"
 height="200"
 loading="lazy"
 decoding="async"
 srcset="small-image.png 300w,
  medium-image.png 600w,
  large-image.png 1200w"
 sizes="(min-width: 66em) 33vw,
  (min-width: 44em) 50vw,
  100vw"
> */


/* good for when grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr)); */
/* sizes="(min-width: 47em) 50vw, 100vw" */

/* good for when minmax(min(100%, 20rem), 1fr) */
/* sizes="(min-width: 66em) 33vw, (min-width: 44em) 50vw, 100vw"  */
/* ^ EXPLANATION ^: In this example, you're telling the browser that above a 
viewport width of 66em to display the image no wider than one third of 
the screen (inside a three column layout, for example). For viewport widths between 
44em and 66em, display the image at half the width of the screen 
(a two column layout).For anything below 44em display the image at 
the full width of the screen 
-- https://web.dev/learn/design/responsive-images/
*/


/* .responsive-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--pad), auto));
    grid-template-rows: repeat(auto-fit, minmax(var(--pad), auto));
} */

/* ======================== SECTIONS  ======================== */

/* .responsive-sections {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 20rem), 1fr));
    gap: var(--pad-xl);
} */


/* ======================== TRANSITION & TRANSFORM  ======================== */

/* .header>.navigation>.nav-items {
    transform: translateX(100%);
    transition: transform .5s cubic-bezier(.13, .86, .13, 1);
}

.header>.navigation>.nav-items[data-visible="true"] {
    transform: translateX(0%);
} */


/* ======================== MAIN BTN (HTML)  ======================== */
/* <a href="#" class="btn main-btn"
><span class="arrow"
><i class="fa-solid fa-chevron-right"
></i></span><span class="main-btn-circle"></span></a> */


/* ======================== Nice Box Shadow  ======================== */

/* box-shadow: 0 3px 10px rgb(0 0 0 / 20%); */