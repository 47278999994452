@import './variables.css';


.header {
    min-height: 100vh;
    position: relative;
    z-index: 1;
}

/* ======================== HEADER NAV ======================== */

.header>.navigation {
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative;
    z-index: 3;
    gap: var(--pad-md);
    text-transform: uppercase;
    align-items: center;
    padding-inline-end: var(--pad-sm);
}

.header>.navigation>.logo {
    background: var(--color-gradient-primary);
    padding-inline-start: var(--pad-xs);
    padding-block-start: .2rem;
    max-height: 7rem;
    aspect-ratio: 3.9;
}

.header>.navigation>#logo {
    width: 100%;
    height: auto;
}

.header>.navigation>.nav-items {
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    gap: var(--pad-sm);
}

.header>.navigation>.nav-items>.nav-item>a {
    position: relative;
    padding: var(--pad-sm);
    letter-spacing: .1rem;
    transition: all .5s cubic-bezier(.13, .86, .13, 1);
    font-weight: 300;
    white-space: nowrap;
    font-size: var(--text);
}

.header>.navigation>.nav-items>.nav-item>a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-gradient-primary-2);
    border-radius: var(--pad-xl);
    transform: scale(0);
    transition: var(--transition);
    z-index: -1;
}

.header>.navigation>.nav-items>.nav-item>a:hover::before {
    transform: scale(1);
}

.active-menu {
    background: var(--color-gradient-primary-2);
    border-radius: var(--pad-xl);
}

@media (max-width: 58.75rem) {

    .header>.navigation {
        align-items: unset;
        gap: unset;
    }

    .header>.navigation>.logo {
        max-height: unset;
    }

    .header>.navigation>.logo img {
        width: 100%;
        height: 100%;
    }

    .header>.navigation>.nav-items {
        transform: translateX(100%);
        transition: transform 1s cubic-bezier(.13, .86, .13, 1);
        display: none;

        background: linear-gradient(rgba(0 0 0 / 40%), rgba(0 0 0 / 40%));
        backdrop-filter: blur(3rem);
        font-size: var(--text-md);

        position: fixed;
        z-index: 1000;
        inset: 0 0 0 30%;
        height: 100vh;

        padding-block: min(30vh, 10rem);
        padding-inline: 2rem;

        font-size: calc(var(--text) * 1.1);
    }

    .header>.navigation>.nav-items>.nav-item:not(:last-child)>a::after {
        content: '';
        position: absolute;
        left: .4rem;
        bottom: 0;
        width: 50%;
        height: .04rem;
        background-color: var(--color-lavender);
    }

    .header>.navigation>.nav-items[data-visible="true"] {
        transform: translateX(0%);

        display: grid;
        grid-auto-flow: row;
        justify-content: start;
        gap: var(--pad-xl);

        overflow-y: auto;
        overscroll-behavior: contain;
    }

    .header>.navigation .nav-item:last-child a {
        font-size: calc(var(--text) * 1.1);
        font-weight: 400;
    }
}

/* ======================== HEADER CONTENT ======================== */

.header-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
    gap: var(--pad-xl);
    margin-block-start: var(--pad-xl);
    text-align: center;
}

/* ======================== HEADER-CONTENT > LEFT ======================== */

.left-h-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    gap: var(--pad-md);
}


.left-h-content h1 {
    font-size: var(--text-xl);
    font-weight: 600;
    letter-spacing: .14rem;
    line-height: var(--pad-xl);
}

.left-h-content h1 .nowrap {
    white-space: nowrap;
}

.baseball {
    aspect-ratio: 1;
    display: inline-block;
    object-fit: contain;
    width: 1ch;
    animation: rotation 6s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);

    }

    to {
        transform: rotate(720deg);
    }
}

.left-h-content h1 span.bg-pan {
    animation: background-pan 6s linear infinite;
    background: linear-gradient(to left,
            var(--color-primary),
            var(--color-accent),
            var(--color-lavender),
            var(--color-primary));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-size: 200%;
    font-weight: 700
}

@keyframes background-pan {
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
}

.left-h-content p {
    font-size: var(--text);
    max-width: 85%;
}

/* ======================== HEADER-CONTENT > RIGHT ======================== */

.right-h-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--pad), auto));
    grid-template-rows: repeat(auto-fit, minmax(var(--pad), auto));
    place-items: center;
}



/* general */
.right-h-content article img {
    border-radius: var(--pad-lg);
    aspect-ratio: 16/9;
    object-fit: cover;
    transition: var(--transition);
}

.right-h-content article h2 {
    background-color: var(--color-neutral);
    color: var(--color-bg-2);
    border: var(--pad-xxs) solid var(--color-grey-6);
    border-radius: var(--text);
    padding: var(--pad-xxs) var(--pad-sm);
    font-weight: 400;
}



/* article 1 */
.right-h-content-article-1 {
    grid-column: 5 / 11;
    grid-row: 1 / 8;
    position: relative;
}

.right-h-content-article-1 h2 {
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: var(--pad-xxs) var(--pad-xxs) 0 var(--color-accent);
}



/* article 2 */
.right-h-content-article-2 {
    grid-column: 1 / 9;
    grid-row: 7 / 14;
    z-index: 1;
    position: relative;
}

.right-h-content-article-2 h2 {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: calc(var(--pad-xxs) * -1) calc(var(--pad-xxs) * -1) 0 var(--color-accent);
}



/* article 3 */
.right-h-content-article-3 {
    grid-column: 5 / 11;
    grid-row: 13 / 20;
    position: relative;
}

.right-h-content-article-3 h2 {
    z-index: 2;
    position: absolute;
    bottom: -.2rem;
    right: 0;
    box-shadow: calc(var(--pad-xxs) * -1) var(--pad-xxs) 0 var(--color-accent);

}




.right-h-content:hover img.h-img-1 {
    transform: scale(1.1);
}

.right-h-content:hover img.h-img-2 {
    transform: scale(0.9);
}

.right-h-content:hover img.h-img-3 {
    transform: scale(1.1);
}



/* ======================== HEADER-CONTENT > WAVES ======================== */

.header-separator {
    margin-block-start: var(--pad-xl);
}



/* Credit: https://codepen.io/goodkatz */

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}



/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    .content {
        height: 30vh;
    }

    h1 {
        font-size: 24px;
    }
}