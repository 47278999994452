@import './variables.css';

.lines {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    z-index: -1;
}

.lines>.line {
    min-height: 100%;
    width: 1px;
    background-color: var(--color-grey-9);
}

/* .mouse {
    margin-inline: auto;
    margin-block: calc(var(--pad-xl) * 3);
    transform: translateX(-50%);
    width: 30px;
    height: 45px;
    border-radius: var(--pad);
    border: calc(var(--text-xs) / 4) solid var(--color-white);
    pointer-events: none;
}

.mouse>.mouse-ball {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--color-gradient-primary-3);
    animation: upDown 1.5s infinite;
} */

@keyframes upDown {
    0% {
        transform: translateY(-13px) translateX(-50%) scale(1.3);
    }

    10% {
        transform: translateY(-13px) translateX(-50%) scale(1.3);
    }

    50% {
        transform: translateY(10px) translateX(-50%) scale(1);
    }

    100% {
        transform: translateY(-13px) translateX(-50%) scale(1);
    }
}