:root {
  --pad-xl: calc(var(--pad) * 4);
  --pad-lg: calc(var(--pad) * 2);
  --pad-md: calc(var(--pad) * 1.5);
  --pad: clamp(1rem, 1vw + .5rem, 3rem);
  --pad-sm: calc(var(--pad) / 2);
  --pad-xs: calc(var(--pad) / 4);
  --pad-xxs: calc(var(--pad) / 6);
  --text-xl: calc(var(--text) * 4);
  --text-lg: calc(var(--text) * 2);
  --text-md: calc(var(--text) * 1.5);
  --text: clamp(1rem, 1rem + .2vw, 1.6rem);
  --text-sm: calc(var(--text) / 2);
  --text-xs: calc(var(--text) / 4);
  --navbar-height: clamp(4.5rem, 6rem, 7.5rem);
  --title-sm: 1.25rem;
  --title: 1.5rem;
  --title-lg: 2rem;
  --color-primary: #554dde;
  --color-accent: #f44e77;
  --color-secondary-dark: #16194f;
  --color-bg: #101010;
  --color-bg-2: #10121a;
  --color-bg-3: #111;
  --color-lavender: #9a94ff;
  --color-grey-light: #191919;
  --color-grey-light-2: #1d1d1d;
  --color-white: #fff;
  --color-black: #000;
  --color-neutral: #f2f6ff;
  --color-gradient-primary: linear-gradient(143.82deg, #554dde 4.75%, #554dde00 61.12%);
  --color-gradient-accent: linear-gradient(132.34deg, #f44e77 -7.22%, #f44e7700 77.9%);
  --color-gradient-primary-2: linear-gradient(171.21deg, #554dde -10.74%, #f44e77 112.82%, #f44e77 112.82%);
  --color-gradient-primary-3: linear-gradient(300.81deg, #554dde -17.99%, #f44e77 111.32%, #f44e77 111.32%);
  --color-gradient-primary-4: linear-gradient(158deg, #9a94ff 0%, #554dde 35%, #f44e7700 100%);
  --color-gray: #6c757d;
  --color-grey: #343a40;
  --color-font: #a2a2a2;
  --color-grey-1: #f8f9fa;
  --color-grey-2: #e9ecef;
  --color-grey-3: #dee2e6;
  --color-grey-4: #ced4da;
  --color-grey-5: #adb5bd;
  --color-grey-6: #6c757d;
  --color-grey-7: #495057;
  --color-grey-8: #343a40;
  --color-grey-9: #212529b7;
  --color-border: #f8f9fa14;
  --transition: all .4s ease-in-out;
}

.lines {
  height: 100%;
  width: 100%;
  z-index: -1;
  justify-content: space-evenly;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.lines > .line {
  min-height: 100%;
  width: 1px;
  background-color: var(--color-grey-9);
}

@keyframes upDown {
  0% {
    transform: translateY(-13px)translateX(-50%)scale(1.3);
  }

  10% {
    transform: translateY(-13px)translateX(-50%)scale(1.3);
  }

  50% {
    transform: translateY(10px)translateX(-50%)scale(1);
  }

  100% {
    transform: translateY(-13px)translateX(-50%)scale(1);
  }
}

.header {
  min-height: 100vh;
  z-index: 1;
  position: relative;
}

.header > .navigation {
  z-index: 3;
  gap: var(--pad-md);
  text-transform: uppercase;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding-inline-end: var(--pad-sm);
  display: grid;
  position: relative;
}

.header > .navigation > .logo {
  background: var(--color-gradient-primary);
  max-height: 7rem;
  aspect-ratio: 3.9;
  padding-block-start: .2rem;
  padding-inline-start: var(--pad-xs);
}

.header > .navigation > #logo {
  width: 100%;
  height: auto;
}

.header > .navigation > .nav-items {
  justify-content: end;
  gap: var(--pad-sm);
  grid-auto-flow: column;
  display: grid;
}

.header > .navigation > .nav-items > .nav-item > a {
  padding: var(--pad-sm);
  letter-spacing: .1rem;
  white-space: nowrap;
  font-weight: 300;
  font-size: var(--text);
  transition: all .5s cubic-bezier(.13, .86, .13, 1);
  position: relative;
}

.header > .navigation > .nav-items > .nav-item > a:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--color-gradient-primary-2);
  border-radius: var(--pad-xl);
  transition: var(--transition);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.header > .navigation > .nav-items > .nav-item > a:hover:before {
  transform: scale(1);
}

.active-menu {
  background: var(--color-gradient-primary-2);
  border-radius: var(--pad-xl);
}

@media (max-width: 58.75rem) {
  .header > .navigation {
    align-items: unset;
    gap: unset;
  }

  .header > .navigation > .logo {
    max-height: unset;
  }

  .header > .navigation > .logo img {
    width: 100%;
    height: 100%;
  }

  .header > .navigation > .nav-items {
    -webkit-backdrop-filter: blur(3rem);
    backdrop-filter: blur(3rem);
    font-size: var(--text-md);
    z-index: 1000;
    height: 100vh;
    font-size: calc(var(--text) * 1.1);
    background: linear-gradient(#0006, #0006);
    padding-block: min(30vh, 10rem);
    padding-inline: 2rem;
    transition: transform 1s cubic-bezier(.13, .86, .13, 1);
    display: none;
    position: fixed;
    inset: 0 0 0 30%;
    transform: translateX(100%);
  }

  .header > .navigation > .nav-items > .nav-item:not(:last-child) > a:after {
    content: "";
    width: 50%;
    height: .04rem;
    background-color: var(--color-lavender);
    position: absolute;
    bottom: 0;
    left: .4rem;
  }

  .header > .navigation > .nav-items[data-visible="true"] {
    justify-content: start;
    gap: var(--pad-xl);
    overscroll-behavior: contain;
    grid-auto-flow: row;
    display: grid;
    overflow-y: auto;
    transform: translateX(0%);
  }

  .header > .navigation .nav-item:last-child a {
    font-size: calc(var(--text) * 1.1);
    font-weight: 400;
  }
}

.header-content {
  gap: var(--pad-xl);
  text-align: center;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
  margin-block-start: var(--pad-xl);
  display: grid;
}

.left-h-content {
  text-align: center;
  justify-content: space-around;
  align-items: center;
  gap: var(--pad-md);
  flex-direction: column;
  display: flex;
}

.left-h-content h1 {
  font-size: var(--text-xl);
  letter-spacing: .14rem;
  font-weight: 600;
  line-height: var(--pad-xl);
}

.left-h-content h1 .nowrap {
  white-space: nowrap;
}

.baseball {
  aspect-ratio: 1;
  object-fit: contain;
  width: 1ch;
  animation: 6s ease-in-out 1s rotation;
  display: inline-block;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(720deg);
  }
}

.left-h-content h1 span.bg-pan {
  background: linear-gradient(to left, var(--color-primary), var(--color-accent), var(--color-lavender), var(--color-primary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
  background-size: 200%;
  font-weight: 700;
  animation: 6s linear infinite background-pan;
}

@keyframes background-pan {
  from {
    background-position: 0%;
  }

  to {
    background-position: -200%;
  }
}

.left-h-content p {
  font-size: var(--text);
  max-width: 85%;
}

.right-h-content {
  grid-template-columns: repeat(auto-fit, minmax(var(--pad), auto));
  grid-template-rows: repeat(auto-fit, minmax(var(--pad), auto));
  place-items: center;
  display: grid;
}

.right-h-content article img {
  border-radius: var(--pad-lg);
  aspect-ratio: 16 / 9;
  object-fit: cover;
  transition: var(--transition);
}

.right-h-content article h2 {
  background-color: var(--color-neutral);
  color: var(--color-bg-2);
  border: var(--pad-xxs) solid var(--color-grey-6);
  border-radius: var(--text);
  padding: var(--pad-xxs) var(--pad-sm);
  font-weight: 400;
}

.right-h-content-article-1 {
  grid-area: 1 / 5 / 8 / 11;
  position: relative;
}

.right-h-content-article-1 h2 {
  z-index: 2;
  box-shadow: var(--pad-xxs) var(--pad-xxs) 0 var(--color-accent);
  position: absolute;
  top: 0;
  right: 0;
}

.right-h-content-article-2 {
  z-index: 1;
  grid-area: 7 / 1 / 14 / 9;
  position: relative;
}

.right-h-content-article-2 h2 {
  z-index: 2;
  box-shadow: calc(var(--pad-xxs) * -1) calc(var(--pad-xxs) * -1) 0 var(--color-accent);
  position: absolute;
  top: 0;
  left: 0;
}

.right-h-content-article-3 {
  grid-area: 13 / 5 / 20 / 11;
  position: relative;
}

.right-h-content-article-3 h2 {
  z-index: 2;
  box-shadow: calc(var(--pad-xxs) * -1) var(--pad-xxs) 0 var(--color-accent);
  position: absolute;
  bottom: -.2rem;
  right: 0;
}

.right-h-content:hover img.h-img-1 {
  transform: scale(1.1);
}

.right-h-content:hover img.h-img-2 {
  transform: scale(.9);
}

.right-h-content:hover img.h-img-3 {
  transform: scale(1.1);
}

.header-separator {
  margin-block-start: var(--pad-xl);
}

.waves {
  width: 100%;
  height: 15vh;
  min-height: 100px;
  max-height: 150px;
  margin-bottom: -7px;
  position: relative;
}

.parallax > use {
  animation: 25s cubic-bezier(.55, .5, .45, .5) infinite move-forever;
}

.parallax > use:nth-child(1) {
  animation-duration: 7s;
  animation-delay: -2s;
}

.parallax > use:nth-child(2) {
  animation-duration: 10s;
  animation-delay: -3s;
}

.parallax > use:nth-child(3) {
  animation-duration: 13s;
  animation-delay: -4s;
}

.parallax > use:nth-child(4) {
  animation-duration: 20s;
  animation-delay: -5s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}

.btn {
  overflow: hidden;
}

.main-btn {
  text-transform: uppercase;
  font-size: var(--text);
  background: var(--color-gradient-primary-3);
  padding: var(--pad);
  border-radius: var(--pad-xl);
  transition: var(--transition);
  white-space: nowrap;
  font-weight: 300;
  position: relative;
}

.main-btn-circle {
  width: 11.25rem;
  height: 11.25rem;
  background: #fff3;
  border-radius: 50%;
  transition: all .5s cubic-bezier(.13, .86, .13, 1);
  position: absolute;
  top: 5px;
  right: -50%;
}

.main-btn:hover .main-btn-circle {
  top: -15px;
  right: -40%;
}

a.more {
  font-size: calc(var(--text) * 1.1);
  padding: var(--pad);
  border: .12rem solid var(--color-grey-7);
  border-radius: var(--pad-xl);
  color: var(--color-accent);
  transition: var(--transition);
  font-weight: 300;
  position: relative;
}

a.more:hover {
  color: var(--color-white);
  background: var(--color-accent);
  transform: translateY(-5px);
}

a.more:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--color-gradient-primary-2);
  border-radius: var(--pad-xl);
  transition: var(--transition);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

a.more:hover:before {
  transform: scale(1);
}

.mobile-nav-toggle {
  --button-color: var(--color-lavender);
  stroke: var(--button-color);
  fill: none;
  background: none;
  border: 0;
  display: none;
}

@media (max-width: 58.75rem) {
  .mobile-nav-toggle {
    z-index: 9999;
    top: 0;
    right: var(--text-xs);
    display: block;
    position: absolute;
  }

  .mobile-nav-toggle .hamburger {
    transition: translate 1s, rotate 1s;
  }

  .mobile-nav-toggle[data-pressed="true"] .hamburger {
    translate: 10px;
    rotate: .125turn;
  }

  .mobile-nav-toggle .line {
    stroke-dasharray: 60 31 40 300;
    stroke-width: 10px;
    transition: all .8s;
  }

  .mobile-nav-toggle[data-pressed="true"] .line {
    stroke-dasharray: 60 105 60 300;
    stroke-dashoffset: -90px;
  }
}

.wrapper {
  width: min(80rem, 100% - 1.25rem);
  padding: var(--pad);
  margin-inline: auto;
}

.section {
  margin-block: var(--pad-xl);
  border-radius: var(--pad);
}

.section-about {
  gap: var(--pad-xl);
  padding-block: var(--pad-xl);
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
  display: grid;
}

.section-about .left-about {
  place-content: center start;
  gap: var(--pad-md);
  display: grid;
  position: relative;
}

article.about-info-item {
  grid-template-columns: var(--pad-xl) 1fr;
  gap: var(--pad);
  display: grid;
}

img[class="about-info-item-img"] {
  background-color: var(--color-white);
  padding: var(--pad-sm);
  object-fit: contain;
  aspect-ratio: 1 / 1;
  border-radius: var(--text-sm);
  width: 100%;
}

article.about-info-item h5 {
  font-size: var(--text);
}

.left-about a {
  justify-self: center;
}

#box-image-left {
  display: none;
}

@media (max-width: 58.75rem) {
  #box-image-left {
    aspect-ratio: 1 / 1;
    animation: 8s infinite scaleUp;
    display: block;
    position: absolute;
    top: -55px;
  }
}

.section-about .right-about {
  transition: var(--transition);
  grid-template-columns: repeat(auto-fit, minmax(var(--pad), auto));
  grid-template-rows: repeat(auto-fit, minmax(var(--pad), auto));
  justify-self: center;
  display: grid;
}

.right-about img[class="box-image"] {
  aspect-ratio: 1 / 1;
  z-index: 1;
  grid-area: 1 / 1 / 4 / 4;
  animation: 8s infinite scaleUp;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.right-about img[class="about-image"] {
  border-radius: var(--pad-xs);
  border-top-left-radius: var(--pad-lg);
  border-bottom-right-radius: var(--pad-lg);
  aspect-ratio: 2 / 3;
  transition: var(--transition);
  z-index: 2;
  background-size: cover;
  grid-area: 2 / 2 / 6 / 8;
}

.section-about:hover img[class="about-image"] {
  transform: scale(1.05);
}

@media (max-width: 58.75rem) {
  .right-about img {
    display: none;
  }
}

.section-services {
  gap: var(--pad-lg);
  margin-block: var(--pad-xl);
  box-shadow: inset 0 0 .3rem .1em var(--color-accent), 0 0 .8em .5em var(--color-primary);
  padding-block: var(--pad-md);
  border-radius: var(--pad);
  display: grid;
}

.services-container {
  gap: var(--pad);
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
  display: grid;
}

.service-item {
  background-color: var(--color-grey-9);
  transition: var(--transition);
  background: linear-gradient(to top, var(--color-grey-9) 55%, var(--color-grey-7));
  border-radius: .44rem;
  position: relative;
}

.service-item:hover {
  box-shadow: .62rem .62rem 0px var(--color-border);
  transform: translateY(-.31rem);
}

.service-item:before {
  content: "";
  height: .4rem;
  background: var(--color-gradient-primary-2);
  width: 100%;
  transition: var(--transition);
  border-bottom-right-radius: .31rem;
  border-bottom-left-radius: .31rem;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

.service-item:hover:before {
  transform: scale(1);
}

.s-item-container {
  padding: var(--pad-xxs);
  text-align: center;
  align-content: space-evenly;
  justify-items: center;
  gap: var(--pad-sm);
  border-radius: .44rem;
  padding-block-end: var(--pad-sm);
  display: grid;
}

@media (max-width: 34em) {
  .s-item-container {
    outline: .1rem solid var(--color-grey-7);
    padding-block-end: var(--pad);
  }
}

.s-item-container img, .s-item-container video, .s-item-container iframe, .s-item-container video-js {
  aspect-ratio: 16 / 9;
  object-fit: contain;
  width: 100%;
  height: 100%;
  border: 0;
  border-top-left-radius: .44rem;
  border-top-right-radius: .44rem;
}

.service-item h4 {
  font-size: var(--text-lg);
  padding-block-end: var(--pad-sm);
  position: relative;
}

.service-item h4:after {
  content: "";
  width: 70%;
  height: .3rem;
  background: var(--color-lavender);
  transition: background .4s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.service-item:hover h4:after {
  background: var(--color-gradient-primary-2);
}

.section-personalized {
  gap: var(--pad);
  background: linear-gradient(291deg, #3f5efb 0%, #fc466b 100%);
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
  margin-block-end: var(--pad-lg);
  display: grid;
  box-shadow: 0 .31rem 1.12rem #212529b7;
}

.section-personalized .left-rec {
  border-top-left-radius: .31rem;
  overflow: hidden;
}

.section-personalized .left-rec img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  filter: grayscale();
  height: 100%;
  transition: var(--transition);
  border-top-left-radius: .31rem;
}

.section-personalized:hover .left-rec img {
  filter: grayscale(0);
  transform: scale(1.05);
}

.section-personalized .right-sec {
  align-content: center;
  display: grid;
}

.section-personalized .right-sec .rec-content {
  justify-items: start;
  gap: var(--pad-md);
  padding: var(--pad);
  display: grid;
}

.section-personalized .right-sec .rec-content h3 {
  font-size: var(--text-lg);
  padding-block-end: var(--pad-md);
  position: relative;
}

.section-personalized .right-sec .rec-content h3:after {
  content: "";
  width: min(20%, 20vw);
  height: .1rem;
  background-color: var(--color-accent);
  border-radius: .37rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-personalized .right-sec .rec-content p {
  color: var(--color-grey-4);
}

.section-faq {
  gap: var(--pad);
  border-radius: var(--pad);
  display: grid;
}

.faq-container {
  background: linear-gradient(to right, var(--color-grey-9) 45%, var(--color-grey-7));
  gap: var(--pad);
  padding: var(--pad);
  text-align: center;
  border-radius: .45rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 20rem), 1fr));
  place-items: center;
  display: grid;
}

@media (max-width: 47em) {
  .faq-container {
    background: linear-gradient(to bottom, var(--color-grey-9) 40%, var(--color-grey-7));
  }
}

.faq-container .faq-img {
  border-radius: .45rem;
  overflow: hidden;
}

.faq-container img {
  object-fit: cover;
  aspect-ratio: 1;
  filter: grayscale(75%);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: .45rem;
}

.section-faq:hover img {
  filter: grayscale(0);
  transform: scale(1.2)rotate(-5deg);
}

.faq-container .faq-text {
  place-items: center;
  gap: var(--pad);
  display: grid;
}

.faq-container .faq-text h4 {
  font-size: calc(var(--text-md) * 1.12);
  font-weight: 600;
}

.faq-container .faq-text h6 {
  font-size: calc(var(--text) * 1.1);
  color: var(--color-font);
  font-weight: 400;
}

.faq-container .faq-text a {
  border: .12rem solid var(--color-grey-4);
  text-transform: initial;
  background: none;
  margin-block-start: var(--pad-xs);
}

.section-faq .faq-controls {
  justify-content: center;
  align-items: center;
  display: flex;
}

.faq-controls .control {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 .2rem;
}

.control-1 {
  background-color: var(--color-accent);
  transform: scale(1.2);
}

.control-2, .control-3 {
  background-color: var(--color-grey-9);
}

section.section-financial {
  background-image: linear-gradient(19deg, #21d4fd 0%, #6a11cb 100%);
  display: grid;
}

.section-financial article p {
  color: var(--color-grey-4);
  letter-spacing: .01ch;
}

.section-financial .financial-container {
  gap: var(--pad);
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 15rem), 1fr));
  display: grid;
}

.financial-item {
  gap: var(--pad-lg);
  font-size: var(--font);
  border: .12rem solid var(--color-grey-9);
  transition: var(--transition);
  cursor: pointer;
  background: linear-gradient(to top, var(--color-grey-9) 40%, var(--color-grey-7));
  border-radius: .44rem;
  display: grid;
}

.financial-item:hover {
  transform: translateY(-5px);
  box-shadow: 10px 10px #f8f9fa14;
}

.financial-item [class="lottie"] {
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  place-self: center;
}

.financial-item iframe {
  width: 100%;
  height: auto;
  border-radius: var(--pad-xs);
  aspect-ratio: 1;
  border: 0;
  place-self: center;
}

.financial-item .image {
  position: relative;
}

.financial-item .image .image-content {
  width: 90%;
  background: var(--color-gradient-primary-3);
  border-radius: var(--pad);
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: absolute;
  bottom: -.81rem;
  left: 50%;
  transform: translateX(-50%);
}

.financial-text {
  place-items: center;
  gap: var(--pad);
  text-align: center;
  padding-inline: var(--pad);
  display: grid;
}

.financial-text h5 {
  font-size: var(--text-md);
  color: var(--color-grey-1);
  transition: var(--transition);
}

.financial-item:hover .financial-text h5 {
  color: var(--color-accent);
}

.financial-text p {
  font-size: var(--text);
}

.financial-icons {
  border-top: 1px solid var(--color-grey-9);
}

.financial-icons ul {
  font-size: var(--text);
  cursor: pointer;
  padding: var(--pad);
  align-items: center;
  gap: .85rem;
  display: flex;
}

.section-video {
  height: 100%;
  width: 100vw;
  aspect-ratio: 16 / 9;
  margin-block: var(--pad-xl);
  box-shadow: 0 5px 18px #212529b7;
  box-shadow: 0 0 .4rem .1rem var(--color-accent), 0 0 2rem 1rem var(--color-primary);
  border-radius: 0;
  position: relative;
}

.section-video video, .section-video video-js, .section-video iframe {
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: center;
  border: 0;
}

.section-video .video-content {
  z-index: 5;
  text-align: center;
  width: 95%;
  border-radius: .44rem;
  place-items: center;
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left-vid-container h4 {
  padding-top: .6rem;
  position: relative;
}

.left-vid-container h4:before {
  content: "";
  width: 100%;
  height: clamp(.2rem, .2rem + .15vh, .3rem);
  background: var(--color-gradient-primary-3);
  border-radius: .44rem;
  position: absolute;
  top: 0;
  left: 0;
}

.input-control {
  justify-content: center;
  align-items: center;
  display: flex;
}

.input-control input {
  width: 100%;
  border: .12rem solid var(--color-grey-9);
  transition: var(--transition);
  z-index: 5;
  text-transform: initial;
  border-radius: 30px;
  outline: none;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  box-shadow: 0 .44rem 18px #212529c9;
}

.input-control input:focus {
  border: clamp(.15rem, .15rem + .15vh, .22rem) solid var(--color-accent);
}

.input-control a {
  text-transform: initial;
  height: 100%;
  width: 55%;
  padding: var(--pad);
}

.overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(#0003, #0003);
  position: absolute;
  top: 0;
  left: 0;
}

.section-gallery {
  gap: var(--pad);
  display: grid;
}

.gallery-container {
  gap: var(--pad);
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.grid-img {
  transition: var(--transition);
  overflow: hidden;
}

.grid-img:hover {
  border-radius: .44rem;
  transform: scale(.94);
}

.grid-img img {
  transition: var(--transition);
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: .44rem .12rem .12rem .44rem;
}

.grid-img img:hover {
  transform: scale(1.2);
}

.grid-img:nth-child(1) img, .grid-img:nth-child(4) img {
  aspect-ratio: 1;
}

.grid-img:nth-child(2) img, .grid-img:nth-child(3) img {
  aspect-ratio: 2;
}

.grid-img:nth-child(2), .grid-img:nth-child(3) {
  grid-column: span 2;
}

.gallery-paragraph {
  text-align: center;
  margin-inline: auto;
}

.footer {
  background-color: var(--color-bg-3);
  color: var(--color-font);
  gap: var(--pad-lg);
  margin-block-start: var(--pad-xl);
  display: grid;
}

.footer h6 {
  font-size: 1.2rem;
}

.footer h4 {
  color: var(--color-grey-4);
  font-size: 1.5rem;
}

.footer a {
  color: var(--color-font);
  transition: var(--transition);
}

.footer a:hover {
  color: var(--color-accent);
  text-decoration: underline;
}

.footer i {
  transition: var(--transition);
  font-size: 1.3rem;
}

.footer i:hover {
  color: var(--color-accent);
}

.footer h1 {
  color: var(--color-white);
}

.bottom-nav {
  gap: var(--pad);
  border-bottom: 1px solid var(--color-grey-9);
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 10rem), 1fr));
  padding-block-end: var(--pad-xl);
  display: grid;
}

.bottom-nav .nav-title {
  padding-block-end: 1.5rem;
}

.bottom-nav .logo-container {
  gap: var(--pad);
  flex-direction: column;
  display: flex;
}

.b-nav-icons {
  gap: var(--pad);
  cursor: pointer;
  display: flex;
}

.nav-bottom .nav-item {
  margin-bottom: 1rem;
}

.nav-bottom-contact .nav-item {
  grid-template-columns: 30px auto;
  display: grid;
}

.ig-images {
  grid-template-rows: repeat(2, .75fr);
  grid-template-columns: repeat(3, 1fr);
  gap: .5rem;
  display: grid;
}

.ig-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  aspect-ratio: 1;
}

.footer-copyright {
  text-align: center;
  margin: var(--pad-sm);
  justify-self: center;
}

.footer-copyright p span {
  color: var(--color-grey-4);
  cursor: pointer;
  transition: var(--transition);
}

.footer-copyright p span:hover {
  color: var(--color-accent);
}

.title {
  font-size: var(--text-lg);
  padding-block-end: var(--pad);
  font-weight: 700;
}

.title-container {
  text-align: center;
  width: min(90%, 60ch);
  padding: var(--pad);
  margin-inline: auto;
}

.arrow svg {
  width: 18px;
  height: 18px;
  animation: 2s infinite moveLR;
}

@keyframes moveLR {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: system-ui;
  font-size: var(--text);
  color: var(--color-white);
  background-color: var(--color-bg);
  max-width: 100%;
  position: relative;
  overflow-x: hidden;
}

img, video, iframe {
  max-inline-size: 100%;
  block-size: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-size: initial;
}

p {
  color: var(--color-font);
  font-weight: 300;
  line-height: 1.6;
}

a {
  font-family: inherit;
  font-weight: 500;
  font-size: inherit;
  color: var(--color-white);
  text-decoration: none;
}

.page-overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.059fb53d.css.map */
